import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Grid } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import revolusendLogo from '../assets/img/revolusend-logo.png';

export const RevolusendAlert = ({
    open,
    onClose
}: {
    open: boolean,
    onClose: () => void,
}) => {
    const { t, i18n } = useTranslation('transferDestination');
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby='alert-dialog'
        >
            <DialogTitle id='dialog-title'>{t('REVOLUSEND_ALERT_TITLE')}</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography>
                            <Trans t={t} i18nKey='REVOLUSEND_ALERT_MESSAGE'>
                                For sending smaller amounts please use our <a href='https://www.revolusend.com'>RevoluSEND</a> platform.
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <a href="https://www.revolusend.com"><img style={{width:'100%'}} src={revolusendLogo}/></a>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color='primary' autoFocus>
                    {'Ok'}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

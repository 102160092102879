import React from 'react';
import { Box, useTheme } from '@material-ui/core'
import RevolupayIcon from '../assets/img/revolupay-icon.png';
import VisaIcon from '../assets/img/visa-icon.png';

export const PaymentMethodBox = ({
    type,
    onClick,
    isSelected
}: {
    type: 'CECA' | 'REVOLUPAY',
    onClick: () => void,
    isSelected: boolean
}) => {
    const theme = useTheme();
    return (
        <Box
            width={'120px'}
            height={'120px'}
            onClick={onClick}
            style={{
                padding:'12px',
                display:'flex',
                justifyContent:'center',
                alignContent:'center',
                alignItems:'center',
                cursor: 'pointer',
                borderColor:isSelected?theme.palette.primary.main:'lightgray',
                borderWidth: isSelected?'2px':'1px',
                borderStyle: 'solid',
                borderRadius: '8px'
            }}>
                <img src={type==='REVOLUPAY'?RevolupayIcon:VisaIcon} width='100%'/>
        </Box>
    )
}

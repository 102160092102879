import { Box, Container, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { LocationProps } from './locationProps.type';
import { Locations } from './locations';


export const LocationsView = (props: RouteComponentProps) => {
  const query = new URLSearchParams(props.location.search);
  const agentId = query.get('agent_id') ? parseInt(query.get('agent_id')!, 10) : undefined;
  const transactionTypeId = query.get('transaction_type_id') ? parseInt(query.get('transaction_type_id')!, 10) : null;
  const amount = query.get('amount') ? parseFloat(query.get('amount')!) : null;
  const countryId = query.get('country_id') ? parseInt(query.get('country_id')!, 10) : null;
  const currencyId = query.get('currency_id') ? parseInt(query.get('currency_id')!, 10) : null;

  const { t } = useTranslation('locations');

  if (!transactionTypeId || !amount || !countryId || !currencyId) {
    return (
      <Redirect to='/' />
    )
  }

  const locationProps: LocationProps = {
    agentId,
    countryId,
    currencyId,
    transactionTypeId,
    amount
  };

  return (
    <Container>
      <Typography variant='h3' gutterBottom>
        {t('VIEW_TITLE')}
      </Typography>
      <Box marginTop={4}>
        <Locations {...locationProps} />
      </Box>
    </Container>
  );
}
import { Typography, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useContext } from '../../context';
import utils from '../../services/utils.service';
import { Quotation } from '../../services/types/quotation.type';


const nonSepaCountries = ['AL', 'AD', 'AZ', 'BH', 'BA', 'BR', 'VG', 'CR', 'DO', 'SV', 'GE', 'GT', 'IQ', 'IL', 'JO', 'KZ', 'QA', 'XK', 'KW', 'LB', 'MR', 'MU', 'MK', 'MD', 'ME', 'PK', 'PS', 'LC', 'ST', 'SA', 'RS', 'SC', 'TL', 'TR', 'TN', 'UA', 'AE', 'BY', 'CU'];

export const QuotationInfo = ({ quotation }: { quotation: Quotation }) => {
  const { t, i18n } = useTranslation(['transferOptions', 'accountType']);
  const context = useContext();
  const theme = useTheme();


  const getAmountRequirementDescription = () => {
    let content = t('UNKNOW_AMOUNT_REQUIREMNT');
    const currency = context.data.productSelectionParams?.currency;

    if(!quotation || !currency){
      return ''
    }
    if (quotation.min_amount && quotation.max_amount && quotation.multiple_of) {
      content = t(
        'REQ_MIN+MAX+MULTIPLE',
        {
          min: `${utils.currencyFormat(quotation.min_amount)} ${currency.iso}`,
          max: `${utils.currencyFormat(quotation.max_amount)} ${currency.iso}`,
          multiple_of: quotation.multiple_of,
          iso: currency.iso
        }
      );
    } else if (quotation.min_amount && quotation.max_amount) {
      content = t(
        'REQ_MIN+MAX',
        {
          min: `${utils.currencyFormat(quotation.min_amount)} ${currency.iso}`,
          max: `${utils.currencyFormat(quotation.max_amount)} ${currency.iso}`,
          iso: currency.iso
        }
      );
    } else if (quotation.min_amount && quotation.multiple_of) {
      content = t(
        'REQ_MIN+MULTIPLE',
        {
          min: `${utils.currencyFormat(quotation.min_amount)} ${currency!.iso}`,
          multiple_of: quotation.multiple_of,
          iso: currency.iso
        }
      );
    } else if (quotation.max_amount && quotation.multiple_of) {
      content = t(
        'REQ_MAX+MULTIPLE',
        {
          max: `${utils.currencyFormat(quotation.max_amount)} ${currency.iso}`,
          multiple_of: quotation.multiple_of,
          iso: currency!.iso
        }
      );
    } else if (quotation.min_amount) {
      content = t(
        'REQ_MIN',
        {
          min: `${utils.currencyFormat(quotation.min_amount)} ${currency.iso}`,
          iso: currency.iso
        }
      );
    } else if (quotation.max_amount) {
      content = t(
        'REQ_MAX',
        {
          max: `${utils.currencyFormat(quotation.max_amount)} ${currency.iso}`,
          iso: currency.iso
        }
      );
    } else {
      content = t(
        'REQ+MULTIPLE',
        {
          multiple_of: quotation.multiple_of,
          iso: currency.iso
        }
      );
    }
    return content
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='subtitle2' component='span'>
            {t('RECEIVE_AMOUNT')}
          </Typography>
          <Typography variant='subtitle1' component='span'>
            {`${utils.currencyFormat(quotation!.receive_amount!)} ${context.data.productSelectionParams?.currency.iso}`}
          </Typography>
          {['ISO20022', 'MANUAL_TRANSFER'].includes(context.data.productSelectionParams!.transactionType.code) && nonSepaCountries.includes(context.data.productSelectionParams!.country.iso2) &&
            <Typography variant='body2' style={{ fontSize: '13px' }} component='p'>
              {t('NO_SEPA_BANKING_FEES')}
            </Typography>
          }
          <Grid item xs={12}>
            <Typography variant='subtitle2' component='span'>
              {t('TOTAL_COST')}
            </Typography>
            <Typography variant='subtitle1' component='span'>
              {`${utils.currencyFormat(quotation.cost)} ${context.data.productSelectionParams?.sourceCurrency.iso}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle2' component='span'>
            {t('EXCHANGE_RATE')}
          </Typography>
          <Typography variant='subtitle1' component='span'>
            {`${utils.currencyFormat(1)} ${context.data.productSelectionParams?.sourceCurrency.iso} =  ${utils.currencyFormat(context.data.productSelectionParams!.exchangeRate, 4)} ${context.data.productSelectionParams?.currency.iso}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle2' component='span'>
            {t('TOTAL_PAY')}
          </Typography>
          <Typography variant='subtitle1' component='span'>
            {`${utils.currencyFormat(quotation.payment_amount)} ${context.data.productSelectionParams?.sourceCurrency.iso}`}
          </Typography>
        </Grid>
        {quotation.differs_from_requested_amount &&
          <Grid item xs={12}>
            <Typography variant='subtitle1'  component='span' style={{fontWeight:'bold'}}>
              {getAmountRequirementDescription()}
            </Typography>
          </Grid>
        }
      </Grid>
    </>
  )
}
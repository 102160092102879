export class Config {

  readonly API_BASE_URL: string;
  readonly GOOGLE_MAPS_API_KEY: string;
  readonly GOOGLE_ANALITYCS_ID?: string;
  readonly TENANT_KEY: string;

  constructor() {
    this.API_BASE_URL = process.env.API_BASE_URL ? process.env.API_BASE_URL : '';
    this.GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY ? process.env.GOOGLE_MAPS_API_KEY : '';
    this.GOOGLE_ANALITYCS_ID = process.env.GOOGLE_ANALITYCS_ID;
    this.TENANT_KEY = process.env.TENANT_KEY ? process.env.TENANT_KEY : '';
  }
}
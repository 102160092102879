import React from 'react';
import { Grid, Typography, CircularProgress, TextField, makeStyles, InputAdornment, Link } from '@material-ui/core';
import { useContext, useApi } from '../../context';
import { TransactionTypeCode } from '../../services/types/transactionType.type';
import { Redirect, useHistory } from 'react-router-dom';
import utils from '../../services/utils.service';
import { Agent } from '../../services/types/agent.type';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AccountType, AccountTypeValidationMethod } from '../../services/types/acocuntType.type';
import { TransferWizard, TransferWizardStep } from '../wizard';
import { Location } from '../../services/types/location.type';
import { Trans, useTranslation } from 'react-i18next';
import IBAN from 'iban';
import { AccountField } from '../../services/types/accountField.type';
import { Quotation } from '../../services/types/quotation.type';
import { QuotationInfo } from './quotationInfo';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

type Errors = {
  account_type: string | undefined;
  accountFields: { [key: string]: string | undefined };
  branch: string | undefined;
}


export const AgentWithAccountSelection = () => {
  const context = useContext();
  const api = useApi();
  const history = useHistory();

  const productSelectionParams = context.data.productSelectionParams

  if (
    !productSelectionParams ||
    ![
      TransactionTypeCode.MOBILE_MONEY,
      TransactionTypeCode.BANK_ACCOUNT,
      TransactionTypeCode.BANK_ACCOUNT_WITH_LOCATIONS,
      TransactionTypeCode.ATM,
      TransactionTypeCode.BANK_TRANSFER,
      TransactionTypeCode.ISO20022,
      TransactionTypeCode.MANUAL_TRANSFER
    ].includes(productSelectionParams.transactionType.code)
  ) {
    return (
      <Redirect to='/' />
    )
  }

  const { t } = useTranslation(['transferOptions', 'accountType']);

  const [quotation, setQuotation] = React.useState<Quotation>();
  const [loadingAgents, setLoadingAgents] = React.useState(true);
  const [loadingAccountTypesAndLocations, setLoadingAccountTypesAndLocations] = React.useState(false);
  const [agents, setAgents] = React.useState<Agent[]>([]);
  const [selectedAgent, selectAgent] = React.useState<Agent | null>(null);
  const [locations, setLocations] = React.useState<Location[]>([]);
  const [selectedLocation, selectLocation] = React.useState<Location | null>(null);
  const [accountTypes, setAccountTypes] = React.useState<AccountType[]>([]);
  const [selectedAccountType, selectAccountType] = React.useState<AccountType | null>(null);
  const [accountFields, setAccountFields] = React.useReducer((
    state: { [key: string]: string },
    action: { action: 'SET_FIELDS' | 'ADD_FIELD', payload: { [key: string]: string } | AccountField }
  ) => {
    if (action.action === 'SET_FIELDS') {
      return action.payload;
    } else {
      state = {
        ...state,
        [action.payload.name]: action.payload.value
      }
      return state;
    }
  }, {} as { [key: string]: string });
  const [errors, setErrors] = React.useState<Errors>({} as Errors);

  const loading = loadingAgents || loadingAccountTypesAndLocations;

  const validate = () => {
    const accountErrors: { [key: string]: string | undefined } = {};
    if (selectedAccountType) {
      for (const field of selectedAccountType.fields) {
        accountErrors[field.name] = undefined;
        if (accountFields[field.name].length === 0) {
          accountErrors[field.name] = t('REQUIRED');
        } else if (field.validation_method === AccountTypeValidationMethod.PHONE) {
          let regex;
          if (field.regex) {
            regex = new RegExp(field.regex)
          } else {
            regex = new RegExp('^[0-9]{5,20}$')
          }
          const isValid = regex.test(accountFields[field.name]);
          if (!isValid) {
            accountErrors[field.name] = t('ACCOUNT_WRONG_FORMAT');
          }
        } else if (field.validation_method === AccountTypeValidationMethod.IBAN) {
          if (!IBAN.isValid(accountFields[field.name])) {
            accountErrors[field.name] = t('ACCOUNT_WRONG_FORMAT');
          }
        } else if (field.validation_method === AccountTypeValidationMethod.REGEX && field.regex) {
          const isValid = new RegExp(field.regex).test(accountFields[field.name]);
          if (!isValid) {
            accountErrors[field.name] = t('ACCOUNT_WRONG_FORMAT');
          }
        }
      }
    }
    const errs = {
      account_type: selectedAccountType === null ? t('REQUIRED') : undefined,
      accountFields: accountErrors,
      branch: (
        context.data.productSelectionParams?.transactionType.code === TransactionTypeCode.BANK_ACCOUNT_WITH_LOCATIONS &&
        selectedLocation === null
      ) ? t('REQUIRED') : undefined
    };
    setErrors(errs);
    return !(
      Object.values(accountErrors).find(i => i !== undefined) !== undefined ||
      errs.account_type !== undefined ||
      errs.branch !== undefined
    );
  }

  const getValidationMethodForField = (accountType: AccountType, fieldName: string): AccountTypeValidationMethod | undefined => {
    return accountType.fields.find(i => i.name === fieldName)?.validation_method;
  }

  const resetErros = () => {
    setErrors({} as Errors);
  }

  const selectProduct = () => {
    if (!validate()) {
      return;
    }
    const parsedAccountFields: AccountField[] = [];
    if (selectedAccountType) {
      for (const accountFieldName of Object.keys(accountFields)) {
        let accountFieldValue = accountFields[accountFieldName];
        if (getValidationMethodForField(selectedAccountType, accountFieldName) === AccountTypeValidationMethod.PHONE) {
          accountFieldValue = `+${context.data.productSelectionParams!.country.prefix}${accountFieldValue}`;
        }
        parsedAccountFields.push({
          name: accountFieldName,
          value: accountFieldValue
        });
      }
    }
    context.setData({
      quotation,
      productAttributes: {
        account: {
          fields: parsedAccountFields,
          type: selectedAccountType!!
        },
        agent: selectedAgent!!,
        location: selectedLocation ? selectedLocation : undefined
      }
    });
    history.push('/transfer/beneficiary')
  }

  React.useEffect(() => {
    setLoadingAgents(true);
    utils.runAsync(async () => {
      const theAgents = await api.getAgents(
        productSelectionParams.country.id,
        productSelectionParams.currency.id,
        productSelectionParams.transactionType.code
      );
      setAgents(theAgents);
      if (theAgents.length === 1) {
        selectAgent(theAgents[0]);
      } else if (context.data.productAttributes?.agent && theAgents.find(a => a.id === context.data.productAttributes?.agent?.id)) {
        selectAgent(context.data.productAttributes?.agent);
      }
    }, () => {
      setLoadingAgents(false);
    });
  }, []);

  React.useEffect(() => {
    resetErros();
    setLoadingAccountTypesAndLocations(true);
    utils.runAsync(async () => {
      if (selectedAgent) {
        const quotation = await api.getQuotation(
          productSelectionParams.country.id,
          productSelectionParams.currency.id,
          productSelectionParams.sourceCurrency.id,
          productSelectionParams.transactionType.code,
          productSelectionParams.amount,
          productSelectionParams.mode,
          selectedAgent!.id
        );
        setQuotation(quotation);
        const theAccountTypes = await api.getAccountTypesForAgent({
          agentId: selectedAgent.id,
          currencyId: productSelectionParams.currency.id,
          countryId: productSelectionParams.country.id,
          transactionTypeId: productSelectionParams.transactionType.id
        });
        setAccountTypes(theAccountTypes);
        if (theAccountTypes.length === 1) {
          selectAccountType(theAccountTypes[0]);
        } else if (context.data.productAttributes?.account && theAccountTypes.find(at => at.id === context.data.productAttributes?.account?.type.id)) {
          selectAccountType(context.data.productAttributes?.account?.type);
        } else {
          selectAccountType(null);
        }
        if (context.data.productSelectionParams?.transactionType.code === TransactionTypeCode.BANK_ACCOUNT_WITH_LOCATIONS) {
          const theLocations = await api.getLocationsForAgentAndCountry(selectedAgent.id, productSelectionParams.country.id);
          setLocations(theLocations);
          if (
            context.data.productAttributes?.location &&
            theLocations.find(l => l.id === context.data.productAttributes?.location?.id)
          ) {
            selectLocation(context.data.productAttributes.location);
          } else {
            selectLocation(null);
          }
        }
      } else {
        setQuotation(undefined);
        selectAccountType(null);
        setAccountTypes([]);
        selectLocation(null);
        setLocations([]);
      }
    }, () => {
      setLoadingAccountTypesAndLocations(false);
    });
  }, [selectedAgent]);

  React.useEffect(() => {
    resetErros();
  }, [selectedLocation]);

  React.useEffect(() => {
    resetErros();
    if (selectedAccountType === null) {
      setAccountFields({ action: 'SET_FIELDS', payload: {} });
    } else if (context.data.productAttributes?.account?.fields && selectedAccountType.id === context.data.productAttributes?.account?.type.id) {
      setAccountFields({
        action: 'SET_FIELDS',
        payload: context.data.productAttributes.account.fields.reduce((p, i) => {
          if (getValidationMethodForField(selectedAccountType, i.name) === AccountTypeValidationMethod.PHONE) {
            p[i.name] = i.value.replace(`+${context.data.productSelectionParams!.country.prefix}`, '');
          } else {
            p[i.name] = i.value;
          }
          return p;
        }, {} as { [key: string]: string })
      });
    } else {
      setAccountFields({
        action: 'SET_FIELDS', payload: selectedAccountType.fields.reduce((p, i) => {
          p[i.name] = '';
          return p;
        }, {} as { [key: string]: string })
      });
    }
  }, [selectedAccountType]);

  const classes = useStyles();

  return (
    <TransferWizard
      step={TransferWizardStep.OPTIONS}
      back={() => history.push('/transfer/destination')}
      canGoNext={!loading && quotation !== undefined}
      next={selectProduct}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            {t('AGENT_WITH_ACCOUNT_SELECTION_INTRO')}
          </Typography>
        </Grid>
        {!loadingAgents &&
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                selectAgent(value);
              }}
              getOptionSelected={(option, value) => option.id === value.id}
              value={selectedAgent}
              options={agents}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Choose an agent')}
                  variant='outlined'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                  }}
                />
              )}
            />
          </Grid>
        }
        {selectedAgent && context.data.productSelectionParams?.transactionType.code === TransactionTypeCode.BANK_ACCOUNT_WITH_LOCATIONS && !loadingAccountTypesAndLocations &&
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                selectLocation(value);
              }}
              getOptionSelected={(option, value) => option.id === value.id}
              value={selectedLocation}
              options={locations}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errors.branch !== undefined}
                  helperText={errors.branch}
                  label={t('Choose a branch')}
                  variant='outlined'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                  }}
                />
              )}
            />
          </Grid>
        }
        {selectedAgent && !loadingAccountTypesAndLocations &&
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                selectAccountType(value);
              }}
              getOptionSelected={(option, value) => option.id === value.id}
              value={selectedAccountType}
              options={accountTypes}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => t(`accountType:${option.name}`)}
              renderOption={(option) => t(`accountType:${option.name}`)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errors.account_type !== undefined}
                  helperText={errors.account_type}
                  label={t('Choose an account type')}
                  variant='outlined'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                  }}
                />
              )}
            />
          </Grid>
        }
        {selectedAccountType && !loadingAccountTypesAndLocations &&
          Object.keys(accountFields).map(f => (
            <Grid key={f} item xs={12}>
              <TextField
                fullWidth={true}
                error={Boolean(errors.accountFields && errors.accountFields[f])}
                helperText={errors.accountFields && errors.accountFields[f]}
                label={t(`accountType:${f}`)}
                variant='outlined'
                value={accountFields[f]}
                onChange={(event) => {
                  resetErros();
                  setAccountFields({ action: 'ADD_FIELD', payload: { name: f, value: event.target.value } });
                }}
                InputProps={{
                  startAdornment: getValidationMethodForField(selectedAccountType, f) === AccountTypeValidationMethod.PHONE ?
                    <InputAdornment position='start'>{`+${productSelectionParams.country.prefix}`}</InputAdornment>
                    : null,
                }}
              />
            </Grid>
          ))
        }
        {!loading && selectedAgent && selectedAgent.name.toLowerCase() === 'alipay' &&
          <Grid item xs={12}>
            <Trans t={t} i18nKey={'ALIPAY_INSTRUCTIONS'}>
              <Typography variant='body2'>
                <Link underline='none' href='https://render.alipay.com/p/w/tutorial-pc/en-us.html' target='_blank' rel='noopener'>
                  LINK
              </Link>
              </Typography>
            </Trans>
          </Grid>
        }
        {!loading && quotation &&
          <Grid item xs={12}>
            <QuotationInfo quotation={quotation} />
          </Grid>
        }
        {loading &&
          <Grid item xs={12}>
            <Grid container justify='center'>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>
    </TransferWizard>
  );
}
import React from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography } from '@material-ui/core';
import peopleImg from '../assets/img/people.svg';
import { useTranslation } from 'react-i18next';
import utils from '../services/utils.service';
import { Link } from 'react-router-dom';
import { Beneficiary } from '../services/types/beneficiary.type';
import { useApi } from '../context';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import { Country } from '../services/types/country.type';
import { LoadingButton } from '../components/loadingButton';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    maxWidth: '90%',
    width: 300,
  },
  listItem: {
    padding: theme.spacing(0.25, 1),
  },
  listItemText: {
    margin: theme.spacing(0)
  }
}));

export const BeneficiaryDetails = ({
  beneficiary,
  onClose,
  countries
}: {
  beneficiary: Beneficiary | null,
  onClose: (deleted: boolean) => void,
  countries: { [key: number]: Country }
}) => {
  const { t } = useTranslation(['account', 'documentTypes']);
  const classes = useStyles();
  const api = useApi();
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  if (!beneficiary) return (<></>);

  const deleteContact = (id: number) => {
    utils.runAsync(async () => {
      setDeleteLoading(true);
      await api.deleteContact(id);
      onClose(true);
    }, () => {
      setDeleteLoading(false);
    });
  }
  return (
    <Dialog
      fullWidth
      open={beneficiary !== null}
      onClose={() => onClose(false)}
      scroll={'paper'}
    >
      <DialogTitle>{t('BENEFICIARY_DETAILS_TITLE')}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={12} container>
              <Grid item xs={12} sm={6}>
                <List disablePadding>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      primary={t('Identification')}
                      secondary={
                        <>
                          <Typography variant='body2'>{`${beneficiary.first_name} ${beneficiary.last_name}`}</Typography>
                          <Typography variant='body2'>{beneficiary.id_card_country_id ? countries[beneficiary.id_card_country_id!].name : ''}</Typography>
                          <Typography variant='body2'>{`${t(`documentTypes:${beneficiary.id_card_type}`)} ${beneficiary.id_card_number}`}</Typography>
                          <Typography variant='body2'>{`${t('ISSUED')} ${beneficiary.id_card_issue_date ? moment(beneficiary.id_card_issue_date).format('MM-YYYY') : ''}`}</Typography>
                          <Typography variant='body2'>{`${t('EXPIRES')} ${beneficiary.id_card_expiry_date ? moment(beneficiary.id_card_expiry_date).format('MM-YYYY') : ''}`}</Typography>
                        </>
                      }
                      className={classes.listItemText}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      primary={t('Contact data')}
                      secondary={
                        <>
                          <Typography variant='body2'>{beneficiary.phone}</Typography>
                          <Typography variant='body2'>{beneficiary.email}</Typography>
                        </>
                      }
                      className={classes.listItemText} />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={6}>
                <List disablePadding>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      primary={t('Address')}
                      secondary={
                        <>
                          <Typography variant='body2'>{beneficiary.address}</Typography>
                          <Typography variant='body2'>{`${beneficiary.postal_code ? beneficiary.postal_code + ',' : ''} ${beneficiary.city}, ${beneficiary.region}`}</Typography>
                          <Typography variant='body2'>{beneficiary.residence_country_id ? countries[beneficiary.residence_country_id!].name : ''}</Typography>
                        </>
                      }
                      className={classes.listItemText} />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={deleteLoading} disabled={deleteLoading} onClick={() => deleteContact(beneficiary.id!)} color="default" variant='outlined' startIcon={<DeleteIcon />}>
          {t('DELETE')}
        </LoadingButton>
        <Button onClick={() => onClose(false)} color="primary">
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const Beneficiaries = () => {
  const { t } = useTranslation('account');

  const api = useApi();

  const [beneficiaries, setBeneficiaries] = React.useState<Beneficiary[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [countries, setCountries] = React.useState<{ [key: number]: Country }>({});
  const [detailBeneficiary, setDetailBeneficiary] = React.useState<Beneficiary | null>(null);

  const loadBeneficiaries = async () => {
    const beneficiaries = await api.getContacts()
    setBeneficiaries(beneficiaries);
  }

  React.useEffect(() => {
    utils.runAsync(async () => {
      const countries = await api.getCountries();
      setCountries(
        countries.reduce((p, c) => {
          p[c.id] = c;
          return p;
        }, {} as { [key: number]: Country })
      );
      await loadBeneficiaries();
    }, () => {
      setLoading(false);
    })
  }, []);
  const classes = useStyles();

  return (
    <>
      <BeneficiaryDetails beneficiary={detailBeneficiary} countries={countries} onClose={(deleted: boolean) => {
        if (deleted) {
          utils.runAsync(
            loadBeneficiaries,
            () => {
              setDetailBeneficiary(null);
            }
          );
        } else {
          setDetailBeneficiary(null);
        }
      }} />
      <Paper>
        <Box paddingTop={2} paddingLeft={1} paddingBottom={1}>
          <Typography variant="h5">
            {t('BENEFICIARIES')}
          </Typography>
        </Box>
        {loading &&
          <Grid item container justify='center'>
            <Box padding={4}>
              <CircularProgress />
            </Box>
          </Grid>
        }
        {!loading && beneficiaries.length > 0 &&
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{t('NAME')}</TableCell>
                    <TableCell>{t('PHONE')}</TableCell>
                    <TableCell>{t('EMAIL')}</TableCell>
                    <TableCell>{t('COUNTRY')}</TableCell>
                    <TableCell>{t('CITY')}</TableCell>
                    <TableCell>{t('POSTAL_CODE')}</TableCell>
                    <TableCell>{t('REGION')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {beneficiaries.map(b => (
                    <TableRow key={b.id}>
                      <TableCell>
                        <IconButton onClick={() => { setDetailBeneficiary(b) }} color='secondary'>
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell>{`${b.first_name} ${b.last_name}`}</TableCell>
                      <TableCell>{b.phone}</TableCell>
                      <TableCell>{b.email}</TableCell>
                      <TableCell>{b.residence_country_id ? countries[b.residence_country_id!].name : ''}</TableCell>
                      <TableCell>{b.city}</TableCell>
                      <TableCell>{b.postal_code}</TableCell>
                      <TableCell>{b.region}</TableCell>
                    </TableRow>

                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        }
        {!loading && beneficiaries.length === 0 &&
          <Box padding={4}>
            <Grid container spacing={1} justify='center'>
              <Grid item>
                <img src={peopleImg} className={classes.img} />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom variant='subtitle2' align='center'>
                  {t('NO_BENEFICIARIES')}
                </Typography>
              </Grid>
              <Grid item>
                <Button component={Link} to='/transfer' variant='outlined' size='large' color='secondary'>{t('NEW_TRANSFER')}</Button>
              </Grid>
            </Grid>
          </Box>
        }
      </Paper>
    </>
  );
}
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { TermsContent } from '../static/terms';


export const LoginConditions = ({
  open,
  onClose,
}: {
  open: boolean,
  onClose: () => void,
}) => {
  const { t } = useTranslation('login');
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={'paper'}
      aria-labelledby='login-conditions'
    >
      <DialogTitle id='dialog-title'>{t('CONDITIONS_MODAL_TITLE')}</DialogTitle>
      <DialogContent dividers={true}>
        <TermsContent />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary' autoFocus>
          {'Ok'}
        </Button>
      </DialogActions>
    </Dialog >
  )
}

export enum PromotionType {
  WELCOME = 'WELCOME', REGULAR = 'REGULAR', VOUCHER = 'VOUCHER'
}

export enum PromotionVerificationError {
  NOT_FOUND = 'NOT_FOUND', INACTIVE ='INACTIVE', USAGE_EXCEEDED = 'USAGE_EXCEEDED', NOT_ALLOWED = 'NOT_ALLOWED'
}

export type Promotion = {
  id: number;
  type: PromotionType;
  start: string;
  end: string;
  voucher?: string;
  amount: number;
  country_id?: number;
  usages: number;
  disabled: boolean;
}
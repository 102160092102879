import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { LocationProps } from './locationProps.type'
import { useTranslation } from 'react-i18next';
import { Locations } from './locations';

export const LocationsModal = (props: LocationProps & { open: boolean; onClose: () => void }) => {
  const { open, onClose } = props;
  const locationProps = props as LocationProps;
  const { t } = useTranslation('locations')
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        {t('MODAL_TITLE')}
      </DialogTitle>
      <DialogContent>
        <Locations {...locationProps} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('CLOSE')}</Button>
      </DialogActions>
    </Dialog>
  )
}
import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route as DomRoute,
  useLocation
} from 'react-router-dom';
import { Home } from './home/home';
import { PaymentKo } from './payment/paymentKo';
import { PaymentOk } from './payment/paymentOk';
import { PaymentCheck } from './payment/paymentCheck';
import { Layout } from './layout/layout';
import { About } from './static/about';
import { Terms } from './static/terms';
import { Privacy } from './static/privacy';
import { Cookies } from './static/cookies';
import { Credits } from './static/credits';
import { Contact } from './contact/contact';
import { Account } from './account/account';
import { Checkout } from './transfer/checkout';
import { Destination } from './transfer/destination';
import { Beneficiary } from './transfer/beneficiary';
import { Options } from './transfer/options';
import { useAuth } from './context';
import { LocationsView } from './locations/locationsView';
import ReactGA from 'react-ga';
import { Config } from './config';
import { Cuba } from './static/cuba';
import { OctKo } from './payment/octKo';
import { OctOk } from './payment/octOk';


const config = new Config();
if (config.GOOGLE_ANALITYCS_ID) {
  ReactGA.initialize(config.GOOGLE_ANALITYCS_ID);
}

const Route = ({ component: Component, ...rest }: { component: any, exact?: boolean, path: string[] | string }) => (
  <DomRoute {...rest} render={(props) => <Layout><Component {...props} /></Layout>} />
)

const ScrollToTop = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (config.GOOGLE_ANALITYCS_ID) {
      ReactGA.pageview(pathname);
    }
  }, [pathname]);
  return null;
}

export const Router = () => {
  const auth = useAuth();
  React.useEffect(() => {
    auth.attemptNonInteractiveLogin();
  }, []);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path={['/transfer/destination', '/transfer']} component={Destination} />
        <Route exact path='/transfer/options' component={Options} />
        <Route exact path='/transfer/beneficiary' component={Beneficiary} />
        <Route exact path='/transfer/checkout' component={Checkout} />
        <Route exact path='/payments/check' component={PaymentCheck} />
        <Route exact path='/payments/ko' component={PaymentKo} />
        <Route exact path='/payments/ok/:transactionReference' component={PaymentOk} />
        <Route exact path='/oct/:transactionReference/ko' component={OctKo}/>
        <Route exact path='/oct/:transactionReference/ok' component={OctOk}/>
        <Route exact path='/about' component={About} />
        <Route exact path='/terms' component={Terms} />
        <Route exact path='/privacy' component={Privacy} />
        <Route exact path='/cookies' component={Cookies} />
        <Route exact path='/credits' component={Credits} />
        <Route exact path='/account' component={Account} />
        <Route exact path='/locations' component={LocationsView} />
        <Route exact path='/cuba' component={Cuba} />
        <Route path='/' component={Home} />
      </Switch>
    </BrowserRouter>
  );
}

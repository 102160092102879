import React from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const About = () => {
  const { t } = useTranslation('about');
  return (
    <Container>
      <Typography variant='h3'>
        {t('ABOUT_US')}
      </Typography>
      <Box marginTop={2}>
        <Typography>
          {t('CONTENT')}
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography>
          {t('CONTENT_EP')}
        </Typography>
      </Box>
    </Container>
  );
}
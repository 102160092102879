import React from 'react'
import { LocationProps } from './locationProps.type'
import { useTranslation } from 'react-i18next';
import { Box, Button, ButtonGroup, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemText, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import notFoundImg from '../assets/img/not-found.svg';
import locationImg from '../assets/img/location.svg';
import { google, Loader } from 'google-maps';
import { Config } from '../config';
import utils from '../services/utils.service';
import { Autocomplete } from '@material-ui/lab';
import { useApi } from '../context';
import { Country } from '../services/types/country.type';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { LocationListItem } from '../services/types/locationListItem.type';

const config = new Config();
const loader = new Loader(config.GOOGLE_MAPS_API_KEY, {});

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    height: 300,
    maxWidth: '100%'
  },
  map: {
    width: '100%',
    height: 600
  },
  listItem: {
    padding: theme.spacing(0.25, 1),
  },
  listItemText: {
    margin: theme.spacing(0)
  },
  clickableCard: {
    cursor: 'pointer'
  },
  mapImage: {
    height: 250,
    maxWidth: '100%'
  }
}));

let geocoder: google.maps.Geocoder;
let timer: NodeJS.Timeout;
let google: google;
let map: google.maps.Map;

const zoom = 8;
const radius = 150;


const DetailLocationModal = ({ open, onClose, detailedLocation }: { open: boolean, onClose: () => void, detailedLocation: LocationListItem | null }) => {
  const { t } = useTranslation('locations');
  const classes = useStyles();
  return (
    <Dialog fullWidth open={open} onClose={() => onClose()}>
      <DialogTitle>{t('LOCATION_DETAIL_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <List disablePadding>
              <ListItem className={classes.listItem}>
                <ListItemText primary={t('NAME')} secondary={detailedLocation?.location.name} className={classes.listItemText} />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText primary={t('AGENT')} secondary={detailedLocation?.agents.map(a => a.name).join(', ')} className={classes.listItemText} />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText primary={t('ADDRESS')} secondary={utils.getOrDefault(detailedLocation?.location.address)} className={classes.listItemText} />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText secondary={`${utils.getOrDefault(detailedLocation?.location.city)}, ${utils.getOrDefault(detailedLocation?.location.postal_code)}`} className={classes.listItemText} />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText primary={t('PHONE')} secondary={detailedLocation?.location.phone ? detailedLocation.location.phone : '-'} className={classes.listItemText} />
              </ListItem>
            </List>
          </Grid>
          {detailedLocation &&
            <Grid container justify='center' item xs={12} sm={6}>
              <img
                className={classes.mapImage}
                src={utils.getStaticMapImage(
                  { lat: detailedLocation!.location.lat!, long: detailedLocation!.location.long! },
                  { lat: detailedLocation!.location.lat!, long: detailedLocation!.location.long! },
                  18,
                  false
                )}
              />
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>{t('CLOSE')}</Button>
      </DialogActions>
    </Dialog>
  );
}

export const Locations = (props: LocationProps) => {
  const { t } = useTranslation('locations')
  const api = useApi();
  const classes = useStyles();

  const [loadingMapsLib, setLoadingMapsLib] = React.useState(true);
  const [loadingLocations, setLoadingLocations] = React.useState(false);
  const [loadingGeocode, setLoadingGeocode] = React.useState(false);
  const [geocodeResults, setGeocodeResults] = React.useState<google.maps.GeocoderResult[]>([]);
  const [country, setCountry] = React.useState<Country | null>(null);
  const [countryGeocodeResult, setCountryGeocodeResult] = React.useState<google.maps.GeocoderResult | null>(null);
  const [selectedGeocode, selectGeocode] = React.useState<google.maps.GeocoderResult | null>(null);
  const [locations, setLocations] = React.useState<LocationListItem[]>([]);
  const [activeView, setActiveView] = React.useState<'LIST' | 'MAP'>('LIST');
  const [detailedLocation, setDetailedLocation] = React.useState<any | null>(null);

  const initMapsLib = () => utils.runAsync(async () => {
    google = await loader.load();
    map = new google.maps.Map(document.getElementById('map')!, {
      center: { lat: 41.4797402, lng: 2.0681046 },
      zoom
    });
    map.addListener('center_changed', () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        loadLocations(map.getCenter());
      }, 500);
    });
    geocoder = new google.maps.Geocoder();
    const foundCountry = (await api.getCountries()).find(c => c.id === props.countryId);
    if (foundCountry) {
      setCountry(foundCountry);
      geocoder.geocode({ componentRestrictions: { country: foundCountry.iso2 } }, (results, status) => {
        if (results && results.length > 0) {
          setCountryGeocodeResult(results[0]);
        }
      });
    }
  }, () => {
    setLoadingMapsLib(false);
  });

  const fetchGeocode = (address: string) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setLoadingGeocode(true);
      const componentRestrictions: google.maps.GeocoderComponentRestrictions = {};
      if (country) {
        componentRestrictions.country = country.iso2;
      }
      geocoder.geocode({ address, componentRestrictions }, (results, status) => {
        setLoadingGeocode(false);
        if (results) {
          setGeocodeResults(results);
        }
      });
    }, 250);
  }

  const loadLocations = (position: google.maps.LatLng) => utils.runAsync(async () => {
    setLoadingLocations(true);
    setLocations(await api.getLocations({
      countryId: props.countryId,
      currencyId: props.currencyId,
      amount: props.amount,
      transactionTypeId: props.transactionTypeId,
      agentId: props.agentId,
      lat: position.lat(),
      long: position.lng(),
      radius
    }));
  }, () => {
    setLoadingLocations(false);
  });


  React.useEffect(() => {
    initMapsLib();
  }, []);

  React.useEffect(() => {
    if (selectedGeocode) {
      loadLocations(selectedGeocode.geometry.location);
    } else {
      setLocations([]);
    }
  }, [selectedGeocode]);

  React.useEffect(() => {
    if (activeView === 'LIST') {
      selectGeocode(null);
    } else if (countryGeocodeResult) {
      map.setCenter(countryGeocodeResult.geometry.location);
      map.setZoom(zoom);
    }
  }, [activeView])

  React.useEffect(() => {
    if (map && activeView === 'MAP' && locations.length > 0) {
      const markers = [];
      for (const location of locations) {
        if (location.location.lat && location.location.long) {
          const marker = new google.maps.Marker({ position: new google.maps.LatLng(location.location.lat, location.location.long) });
          marker.addListener('click', () => {
            setDetailedLocation(location);
          });
          markers.push(marker);
        }
      }
      const markerCluster = new MarkerClusterer(map, markers, { imagePath: '/m' });
    }
  }, [locations]);



  return (
    <>
      <DetailLocationModal open={detailedLocation !== null} detailedLocation={detailedLocation} onClose={() => setDetailedLocation(null)} />
      <Grid container spacing={2}>
        {loadingMapsLib &&
          <Grid item container xs={12} justify='center'>
            <CircularProgress />
          </Grid>
        }
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} container justify='center'>
              <ButtonGroup>
                <Button
                  onClick={() => setActiveView('LIST')}
                  variant={activeView === 'LIST' ? 'contained' : 'outlined'}
                  color='primary'>
                  {t('LIST_VIEW_BUTTON_TITLE')}
                </Button>
                <Button
                  onClick={() => setActiveView('MAP')}
                  disabled={loadingMapsLib}
                  color={'primary'}
                  variant={activeView === 'MAP' ? 'contained' : 'outlined'}>
                  {t('MAP_VIEW_BUTTON_TITLE')}
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <Box display={activeView === 'LIST' ? 'inherit' : 'none'}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      fullWidth
                      disabled={loadingMapsLib}
                      value={selectedGeocode}
                      onChange={(e, value) => selectGeocode(value)}
                      getOptionSelected={(option, value) => option.formatted_address === value.formatted_address}
                      getOptionLabel={option => option.formatted_address}
                      onInputChange={(e, value) => fetchGeocode(value)}
                      filterOptions={() => geocodeResults}
                      options={geocodeResults}
                      loading={loadingGeocode}
                      noOptionsText={t('NO_GEOCODE')}
                      loadingText={t('LOADING')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('SELECT_GEOCODE')}
                          variant='outlined'
                          helperText={!loadingMapsLib ? t('GEOCODE_HELPER_TEXT', { country: country?.name ? country.name : '' }) : ''}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'chrome-off',
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingGeocode ? <CircularProgress size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {(loadingLocations) &&
                    <Grid item container xs={12} justify='center'>
                      <CircularProgress />
                    </Grid>
                  }
                  {(!selectedGeocode || (loadingLocations && locations.length === 0)) &&
                    <Grid item xs={12}>
                      <Grid container spacing={2} justify='center'>
                        <Grid item xs={12}>
                          <Box marginTop={4}>
                            <Typography variant='h6' align='center'>
                              {t('SELECT_GEOCODE_DESCRIPTION')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item container justify='center' xs={12}>
                          <img className={classes.img} src={locationImg} />
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                  {(!loadingLocations && selectedGeocode && locations.length === 0) &&
                    <Grid item xs={12}>
                      <Grid container spacing={2} justify='center'>
                        <Grid item container justify='center' xs={12}>
                          <img className={classes.img} src={notFoundImg} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography align='center'>
                            {t('NO_DATA')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                  {(!loadingLocations && selectedGeocode && locations.length > 0) &&
                    <Grid item container alignItems='stretch' spacing={2} xs={12}>
                      {locations.slice(0, Math.min(locations.length, 50)).map(l => (
                        <Grid item onClick={() => { setDetailedLocation(l) }} xs={12} sm={6} md={4} key={l.id}>
                          <Card className={classes.clickableCard}>
                            <CardContent>
                              <Typography variant='subtitle2' color='textPrimary'>
                                {l.location.name}
                              </Typography>
                              <Typography variant='subtitle2' color='textSecondary' component='p' gutterBottom>
                                {l.agents.map(a => a.name).join(', ')}
                              </Typography>
                              <Typography variant='body2' color='textPrimary' component='p' gutterBottom>
                                {`${l.location.address}, ${l.location.city}, ${l.location.postal_code}`}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  }
                </Grid>
              </Box>
              <Box display={activeView === 'MAP' ? 'inherit' : 'none'}>
                <Grid item container justify='center' xs={12}>
                  <div id='map' className={classes.map}></div>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
import React from 'react';
import { Grid, Typography, CircularProgress, Button } from '@material-ui/core';
import { useContext, useApi } from '../../context';
import { TransactionTypeCode } from '../../services/types/transactionType.type';
import { Redirect, useHistory } from 'react-router-dom';
import utils from '../../services/utils.service';
import { TransferWizard, TransferWizardStep } from '../wizard';
import { useTranslation } from 'react-i18next';
import PinDropIcon from '@material-ui/icons/PinDrop';
import { LocationsModal } from '../../locations/locationsModal';
import { QuotationInfo } from './quotationInfo';
import { Quotation } from '../../services/types/quotation.type';

export const NoOptions = () => {
  const context = useContext();
  const api = useApi();
  const history = useHistory();

  const productSelectionParams = context.data.productSelectionParams

  if (
    !productSelectionParams ||
    ![
      TransactionTypeCode.CASH,
    ].includes(productSelectionParams.transactionType.code)
  ) {
    return (
      <Redirect to='/' />
    )
  }

  const { t } = useTranslation(['transferOptions', 'accountType']);

  const [quotation, setQuotation] = React.useState<Quotation>();
  const [loadingProduct, setLoadingProduct] = React.useState(true);
  const [showLocationModal, setShowLocationModal] = React.useState(false);

  const loading = loadingProduct;

  const selectProduct = () => {
    context.setData({
      quotation
    });
    history.push('/transfer/beneficiary')
  }


  React.useEffect(() => {
    utils.runAsync(async () => {
      setLoadingProduct(true);
      const quotation = await api.getQuotation(
        productSelectionParams.country.id,
        productSelectionParams.currency.id,
        productSelectionParams.sourceCurrency.id,
        productSelectionParams.transactionType.code,
        productSelectionParams.amount,
        productSelectionParams.mode
      );
      setQuotation(quotation);
    }, () => {
      setLoadingProduct(false);
    });
  }, []);

  return (
    <>
      <LocationsModal
        open={showLocationModal}
        onClose={() => setShowLocationModal(false)}
        transactionTypeId={context.data.productSelectionParams!.transactionType.id}
        countryId={context.data.productSelectionParams!.country.id}
        currencyId={context.data.productSelectionParams!.currency.id}
      />
      <TransferWizard
        step={TransferWizardStep.OPTIONS}
        back={() => history.push('/transfer/destination')}
        canGoNext={!loading && quotation !== undefined}
        next={selectProduct}>
        <Grid container spacing={2}>
          {quotation && context.data.productSelectionParams?.transactionType.code === TransactionTypeCode.CASH &&
            <Grid container item justify='center' xs={12}>
              <Typography gutterBottom style={{ marginBottom: 10 }}>{t('PICKUP_LOCATIONS_DESCRIPTION')}</Typography>
              <Button
                startIcon={<PinDropIcon />}
                color='secondary'
                size='large'
                variant='outlined'
                onClick={() => {
                  setShowLocationModal(true);
                }}
              >{t('VIEW_PICKUP_LOCATIONS')}
              </Button>
            </Grid>
          }
          {!loading && quotation &&
            <Grid item xs={12}>
              <QuotationInfo quotation={quotation} />
            </Grid>
          }
          {loading &&
            <Grid item xs={12}>
              <Grid container justify='center'>
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      </TransferWizard>
    </>
  );
}
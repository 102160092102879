import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import okImg from '../assets/img/success.svg'
import { useContext } from '../context';

export const OctOk = () => {
  const { t } = useTranslation('payment');

  const context = useContext();
  React.useEffect(() => {
    context.reset();
  }, []);

  return (
    <Container>
      <Grid container justify='center' spacing={4}>
        <Grid item xs={12}>
          <Typography variant='h4' align='center'>
            {t('OCT_OK_TITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12} container justify='center'>
          <img src={okImg} style={{ maxWidth: 200 }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' align='center'>
            {t('OCT_OK_SUBTITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align='center'>
            {t('OCT_OK_DESCRIPTION')}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
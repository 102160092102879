export enum AccountTypeValidationMethod {
  IBAN = 'IBAN', PHONE = 'PHONE', REGEX = 'REGEX', NONE = 'NONE'
}

export type AccountTypeField = {
  name: string;
  validation_method: AccountTypeValidationMethod;
  regex?: string;
}

export type AccountType = {
  id: number;
  name: string;
  fields: AccountTypeField[];
}
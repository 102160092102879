import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import * as en from './assets/locale/en/*.json'
import * as es from './assets/locale/es/*.json'
// import * as fr from './assets/locale/fr/*.json'

let lng: string | undefined;

if (window.location.hostname.includes('RevoluTransfer.es')) {
  lng = 'es';
} 

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en, es
    },
    fallbackLng: 'en',
    lng,
    debug: true,
    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;
export enum TransactionTypeCode {
  MOBILE_MONEY = 'MOBILE_MONEY',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  BANK_ACCOUNT_WITH_LOCATIONS = 'BANK_ACCOUNT_WITH_LOCATIONS',
  ATM = 'ATM',
  CASH = 'CASH',
  CASH_TO_AGENT = 'CASH_TO_AGENT',
  BANK_TRANSFER = 'BANK_TRANSFER',
  HOME_DELIVERY = 'HOME_DELIVERY',
  ISO20022 = 'ISO20022',
  MANUAL_TRANSFER = 'MANUAL_TRANSFER',
  OCT='OCT'
}

export type TransactionType = {
  id: number;
  code: TransactionTypeCode;
}
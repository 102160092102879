import React from 'react';
import { Container, Typography, Box, Link } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';

export const Cuba = () => {
  const { t } = useTranslation('cuba');
  return (
    <Container>
      <Typography variant='h3' gutterBottom>
        {t('CUBA_INFORMATION')}
      </Typography>
      <Box marginTop={4}>
        <Typography variant='h4' gutterBottom>
          {t('QUESTION_0_TITLE')}
        </Typography>
        <Typography variant='body1' gutterBottom>
          {t('QUESTION_0_CONTENT')}
        </Typography>
      </Box>
      <Box marginTop={4}>
        <Typography variant='h4' gutterBottom>
          {t('QUESTION_1_TITLE')}
        </Typography>
        <Typography variant='body1' gutterBottom>
          {t('QUESTION_1_CONTENT')}
        </Typography>
      </Box>
      <Box marginTop={4}>
        <Typography variant='h4' gutterBottom>
          {t('QUESTION_2_TITLE')}
        </Typography>
        <Typography variant='body1' gutterBottom>
          {t('QUESTION_2_CONTENT')}
        </Typography>
        <Box>
          <video
            controls
            style={{
              width: 720,
              height: 'auto',
              maxWidth: '100%',
              display: 'block',
              margin: '40px auto 20px auto'
            }}>
            <source src="video-explicativo.mp4" type="video/mp4" />
          </video>
        </Box>
      </Box>
      <Box marginTop={4}>
        <Typography variant='h4' gutterBottom>
          {t('QUESTION_3_TITLE')}
        </Typography>
        <Typography variant='body1' gutterBottom>
          {t('QUESTION_3_CONTENT')}
        </Typography>
      </Box>
      <Box marginTop={4}>
        <Typography variant='h4' gutterBottom>
          {t('QUESTION_4_TITLE')}
        </Typography>
        <Typography variant='body1' gutterBottom>
          {t('QUESTION_4_CONTENT_1')}
        </Typography>
        <Typography variant='body1' gutterBottom>
          {t('QUESTION_4_CONTENT_2')}
        </Typography>
      </Box>
      <Box marginTop={4}>
        <Typography variant='h4' gutterBottom>
          {t('QUESTION_5_TITLE')}
        </Typography>
        <Typography variant='body1' gutterBottom>
          {t('QUESTION_5_CONTENT')}
        </Typography>
      </Box>
      <Box marginTop={4}>
        <Typography variant='h4' gutterBottom>
          {t('QUESTION_6_TITLE')}
        </Typography>
        <Typography variant='body1' gutterBottom>
          {t('QUESTION_6_CONTENT')}
        </Typography>
      </Box>
      <Box marginTop={4}>
        <Typography variant='h4' gutterBottom>
          {t('QUESTION_7_TITLE')}
        </Typography>
        <Typography variant='body1' gutterBottom>
          {t('QUESTION_7_CONTENT')}
        </Typography>
      </Box>
    </Container >
  );
}
import React from 'react';
import { Container, Typography, Box, Link } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';

export const Credits = () => {
  const { t } = useTranslation('credits');
  return (
    <Container>
      <Typography variant='h3' gutterBottom>
        {t('CREDITS')}
      </Typography>
      <Trans t={t} i18nKey={'CREDITS_CONTENT'}>
        <Typography paragraph align='justify'>
          This website uses graphics and libraries which are licensed as follows
        </Typography>
      </Trans>
      <Box marginTop={2}>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('GRAPHICS_TITLE')}
          </Typography>
          <ul>
            <li>
              <a href="https://www.freepik.com/vectors/people">People vector created by pch.vector - www.freepik.com</a>
            </li>
            <li>
              <a href='https://www.freepik.com/vectors/background'>Background vector created by pch.vector - www.freepik.com</a>
            </li>
            <li>
              <a href="https://www.freepik.com/vectors/people">People vector created by pikisuperstar - www.freepik.com</a>
            </li>
            <li>
              <a href='https://www.freepik.com/vectors/birthday'>Birthday vector created by pch.vector - www.freepik.com</a>
            </li>
            <li>
              <a href='https://www.freepik.com/vectors/business'>Business vector created by pch.vector - www.freepik.com</a>
            </li>
            <li>
              <a href='https://www.freepik.com/vectors/sale'>Sale vector created by pch.vector - www.freepik.com</a>
            </li>
          </ul>
        </Box>
      </Box>
    </Container >
  );
}